import * as React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import Slideshow from '../components/Slideshow/Slideshow';

const GalleryPage = () => {
  return (
    <Layout>
      <Seo title="Traditional Artwork" description="Drawing and Painting" />
      <h1>Drawing & Painting</h1>
      <h2>Portraiture</h2>
      <Slideshow />
      <h2>2008 Series</h2>
    </Layout>
  )
};

export default GalleryPage;
